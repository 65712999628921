<template>
  <div
      class="w-full bg-slate-50 min-h-[315px] gap-8 bg-cover bg-top-center bg-no-repeat pt-24"
  >
    <div class="max-w-5xl mx-auto pb-24 px-6 lg:px-0">
      <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div>
          <div class="mb-4 text-xs uppercase">Uns kontaktieren</div>
          <div>
            Kölner Straße 42<br />
            40211 Düsseldorf<br />
            Tel.: +49 (0) 211 36 36 55<br />
            E-Mail: info@kwik-color.de
          </div>
          <ul class="flex flex-col space-y-2 mt-4">
          </ul>
        </div>
        <div>
          <div class="mb-4 text-xs uppercase">Mehr von uns</div>
          <ul class="flex flex-col space-y-2">
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">Über uns</router-link></li>
            <li><router-link to="/service">Services</router-link></li>
            <li><router-link to="/contact">Kontakt</router-link></li>
          </ul>
        </div>
        <div class="hidden">
          <div class="mb-4 text-xs uppercase">Folgen Sie uns</div>
          <ul class="flex gap-2">
            <li><a href="#">Instagram</a></li>
            <li><a href="#">LinkedIn</a></li>
            <li><a href="#">Twitter</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="px-3 border-t-2 border-t-gray-300/50 py-8">
      <div class="max-w-5xl mx-auto flex space-x-4 items-start text-sm">
        <div><router-link to="/private-policy">Datenschutz</router-link></div>
        <div><router-link to="/imprint">Impressum</router-link></div>
        <div><router-link to="/cookie-policy">Cookie Richtlinie</router-link></div>
        <div>&copy; {{ currentYear }}. Kwik Color GmbH</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const currentYear = computed(() => new Date().getFullYear());
</script>